import axios from 'axios';
import authHeader from './auth-header';

const ENDPOINT_BASE_URL = `${process.env.VUE_APP_API_URL}`;

class UserService {
    getPublicContent() {
        return axios.get(ENDPOINT_BASE_URL + 'utenti/' + this.$store.state.auth.user.id);
    }

    getUserBoard() {
        return axios.get(ENDPOINT_BASE_URL + 'utenti', { headers: authHeader() });
    }

    getModeratorBoard() {
        return axios.get(ENDPOINT_BASE_URL + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(ENDPOINT_BASE_URL + 'admin', { headers: authHeader() });
    }
}

export default new UserService();